<template>
  <ul
    :class="['forecast-widget__list', {
      'forecast-widget__list--column': column
    }]"
  >
    <li
      v-for="widget in widgets"
      :key="widget.requestKey"
      :class="['forecast-widget__item', {
        'forecast-widget__item--split': isSplitWidget(widget)
      }]"
    >
      <SlSelect
        v-if="widget.type === controlType.select"
        :options="getOptions(widget.selection)"
        :value="widget.val"
        menu-z-index="10001"
        :select-label="getWidgetLabel(widget)"
        @input="(val) => updateWidget(val, widget)"
      >
        <template
          v-if="isCustomLabelWidget(widget)"
          #singleLabel
        >
          <span class="sl-select__label-key">
            {{ getCustomLabel(widget) }}:
          </span>
          <span class="sl-select__label-value">
            {{ getCustomValue(widget) }}
          </span>
        </template>
      </SlSelect>
      <SlSelect
        v-else-if="widget.type === controlType.comboBox"
        :value="widget.val"
        :options="getAggregationOptions(widget.selection)"
        :close-on-select="false"
        class="sl-aggregation-select"
        allow-empty
        @input="(val) => updateWidget(val, widget)"
      >
        <template #selection>
          <span class="sl-aggregation-select__label">
            {{ getCustomLabel(widget) }}:
          </span>
          <span
            :class="['sl-aggregation-select__value', {
              'special': isLabelSpecial(widget)
            }]"
          >
            {{ getAggregationLabel(widget) }}
          </span>
        </template>
        <template #selectOption="{ option, toggle }">
          <div
            class="sl-aggregation-select__option"
            @click="option.isSpecial && toggle()"
          >
            <SlCheckbox
              v-if="!option.noCheckbox"
              :value="option.status"
            />
            <div
              :class="['sl-aggregation-select__option-label', {
                'sl-aggregation-select__option-label--special': option.isSpecial
              }]"
            >
              {{ option.label }}
            </div>
          </div>
        </template>
      </SlSelect>
      <SlCheckbox
        v-else-if="widget.type === controlType.checkbox"
        :id="widget.requestKey"
        v-model="widget.val"
        :disabled="!widget.enabled"
        :label="widget.name"
        :toggle="widget.requestKey === widgetKeys.ACTUAL_SALES_PERIODS_CHECKBOX"
        @change="(val) => updateWidget(val, widget)"
      />
      <SlControlInput
        v-else-if="widget.type === controlType.numberInput"
        :value="widget.val"
        :disabled="!widget.enabled"
        :min="widget.min"
        :max="widget.max"
        :label="getControlInputLabel(widget)"
        @input="(val) => updateWidget(+val || 1, widget)"
      />
    </li>
  </ul>
</template>

<script>

import { mapActions } from 'vuex';
import { controlType, widgetKeys } from '@/config/report/forecastReport.config';
import { DISABLED_STYLE } from '@/config/report';

export default {
  name: 'ForecastWidgets',
  props: {
    widgets: {
      type: Array,
      required: true
    },
    column: Boolean
  },
  data() {
    return {
      widgetKeys,
      controlType,
      customLabelWidgetKeys: [
        widgetKeys.ARCHIVE_PERIOD,
        widgetKeys.AGGREGATE_KEYS
      ],
      splitWidgetKeys: [
        widgetKeys.ACTUAL_SALES_PERIODS_CHECKBOX,
        widgetKeys.ACTUAL_SALES_PERIODS
      ]
    };
  },
  inject: ['toggleIsTableUpdating'],
  computed: {
    hasActualCheckbox() {
      return this.widgets.some(({ requestKey }) => requestKey === widgetKeys.ACTUAL_SALES_PERIODS_CHECKBOX);
    }
  },
  methods: {
    ...mapActions({
      setControl: 'forecastReport/setReportToolbar'
    }),
    async updateWidget(value, { requestKey }) {
      try {
        this.toggleIsTableUpdating(true);

        await this.setControl({
          [requestKey]: value
        });
      } finally {
        this.toggleIsTableUpdating(false);
      }
    },
    isSplitWidget({ requestKey }) {
      return this.splitWidgetKeys.includes(requestKey) && this.hasActualCheckbox;
    },
    isCustomLabelWidget({ requestKey }) {
      return this.customLabelWidgetKeys.includes(requestKey);
    },
    isLabelSpecial({ val, selection }) {
      if (!val) {
        return false;
      }

      const options = this.getAggregationOptions(selection);

      return options.some(({ value, isSpecial }) => val.includes(value) && isSpecial);
    },
    getControlInputLabel(widget) {
      return !this.isSplitWidget(widget) ? widget.name : '';
    },
    getWidgetLabel({ requestKey, name }) {
      return requestKey === widgetKeys.SUM_BY ? name : '';
    },
    getOptions(options) {
      if (!options) {
        return [];
      }

      return options.map(item => ({
        ...item,
        $isDisabled: item.style === DISABLED_STYLE
      }));
    },
    getAggregationOptions(options) {
      if (!options) {
        return [];
      }

      return options.map((item, index) => ({
        ...item,
        isSpecial: index <= 1
      }));
    },
    getAggregationLabel({ val, selection } = {}) {
      if (!val) {
        return '';
      }

      return selection.reduce((acc, { status, label }) => {
        if (status) {
          acc.push(label);
        }

        return acc;
      }, []).join(', ');
    },
    getCustomLabel({ name }) {
      if (!name) {
        return '';
      }

      return name.trim();
    },
    getCustomValue({ val, selection }) {
      if (!selection) {
        return '';
      }

      const item = selection.find(item => item.value === val);

      return item?.label || '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/report/forecast/forecast-widgets";
</style>