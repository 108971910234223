<template>
  <SlGroupDropdown
    :key="isImportChangesEnabled"
    :auto-hide="!isConfigurationVisible"
    @toggle="handleDropdownToggle"
  >
    <SlGroupDropdownTransition>
      <template #group>
        <div
          v-if="!isShowHideVisible && !isConfigurationVisible"
          key="rootContent"
          class="group"
        >
          <SlGroupDropdownOption :title="$t('Web.Report.ViewOptions')">
            <SlDropdownOption
              v-if="configurationWidgets.length"
              round
              @select="toggleConfiguration"
            >
              <template #prepend>
                <icon
                  data="@icons/filter.svg"
                  class="fill-off size-16 color-grey-50"
                />
              </template>
              {{ $t('Web.Report.Configuration') }}
              <template #append>
                <icon
                  data="@icons/chevron_right.svg"
                  class="fill-off size-16 color-grey-60 "
                />
              </template>
            </SlDropdownOption>
            <SlDropdownOption
              round
              @select="toggleShowHide"
            >
              <template #prepend>
                <icon
                  data="@icons/table.svg"
                  class="fill-off size-16 color-grey-50"
                />
              </template>
              {{ $t('Web.Report.ShowColumns') }}
              <template #append>
                <span class="body-1 grey-50">
                  {{ $t('Web.Report.ColumnsShown', { 1: columnsVisibility.visibleCount }) }}
                </span>
                <icon
                  data="@icons/chevron_right.svg"
                  class="fill-off size-16 color-grey-60 "
                />
              </template>
            </SlDropdownOption>
          </SlGroupDropdownOption>
          <SlGroupDropdownOption
            :title="$t('Web.Report.ExportImport')"
            divider
          >
            <SlDropdown placement="left-start">
              <template #target>
                <SlDropdownOption round>
                  <template #prepend>
                    <icon
                      data="@icons/export.svg"
                      class="fill-off size-16 color-grey-50"
                    />
                  </template>
                  {{ $t('Web.Report.ExportReport') }}
                  <template #append>
                    <icon
                      data="@icons/chevron_right.svg"
                      class="fill-off size-16 color-grey-60 "
                    />
                  </template>
                </SlDropdownOption>
              </template>
              <template #content>
                <SlDropdownOption
                  select-close
                  @select="handleExportTableToXlsx(false)"
                >
                  {{ $t('Main.Ui.acChooseExportToXlsx') }}
                </SlDropdownOption>
                <SlDropdownOption
                  :disabled="!isActualSales"
                  select-close
                  @select="handleExportTableToXlsx(true)"
                >
                  {{ $t('Web.File.XLSXTransactionalFormal') }}
                </SlDropdownOption>
                <SlDropdownOption
                  :disabled="isExportToDatabaseDisabled"
                  select-close
                  @select="handleExportTableToDatabase"
                >
                  {{ $t('Main.Ui.acChooseExportToDatabase') }}
                </SlDropdownOption>
              </template>
            </SlDropdown>
            <SlUploader
              v-if="isImportChangesEnabled"
              v-slot="{ open }"
              :accept="xlsxAccepted"
              @file-changed="handleImportChanges"
            >
              <SlDropdownOption
                :disabled="!$sl_tabEditable"
                select-close
                round
                @select="open"
              >
                <template #prepend>
                  <icon
                    data="@icons/import.svg"
                    class="fill-off size-16 color-grey-50"
                  />
                </template>
                <template>
                  {{ $t('Main.Ui.acImportListFromXlsx') }}
                </template>
              </SlDropdownOption>
            </SlUploader>
          </SlGroupDropdownOption>
        </div>
      </template>
      <template #category>
        <ShowHideCategory
          v-if="isShowHideVisible"
          key="ShowHideCategory"
          class="category"
          :options="columnsVisibility.columns"
          unique-key="columnKey"
          @back="toggleShowHide"
          @toggle-visibility="handleToggleColumnVisibility"
        />
        <ConfigurationCategory
          v-else-if="isConfigurationVisible"
          key="ConfigurationCategory"
          :widgets="configurationWidgets"
          class="category"
          @back="toggleConfiguration"
        />
      </template>
    </SlGroupDropdownTransition>
  </SlGroupDropdown>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { hideAllPoppers } from 'floating-vue';
import ShowHideCategory from '@/components/Shared/OptionsMenu/ShowHideCategory.vue';
import ConfigurationCategory from '@/components/Report/Toolbar/Forecast/ConfigurationCategory.vue';
import { access } from '@/mixins/access';
import { fileSaver } from '@/mixins/webAPI';
import { namespaceByRoute } from '@/config/report';
import modalsId from '@/config/shared/modalsId.config';
import { xlsxAccepted } from '@/config/utils/fileUpload.config';
import { supportedExportFgs } from '@/config/shared/fgs.config';
import { actualSalesKey, configurationWidgetKeys, widgetKeys } from '@/config/report/forecastReport.config';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'ForecastOptionsDropdown',
  components: {
    ConfigurationCategory,
    ShowHideCategory
  },
  mixins: [access, fileSaver],
  data() {
    return {
      xlsxAccepted,
      isShowHideVisible: false,
      isConfigurationVisible: false
    };
  },
  inject: [
    'handleExportTableToDatabase'
  ],
  computed: {
    ...mapState({
      widgets: (state) => state.forecastReport.toolbar
    }),
    ...mapGetters({
      canExport: 'project/canExport'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    columnsVisibility() {
      return this.$store.state[`${this.reportNamespace}`]?.columns_visibility || {};
    },
    configurationWidgets() {
      return this.widgets.filter(({ requestKey }) => configurationWidgetKeys.includes(requestKey));
    },
    isExportToDatabaseDisabled() {
      // fgs inv = opt
      return !(this.canExport & supportedExportFgs.INVENTORY) || !this.$sl_hasAccess(this.$sl_features.exportTables);
    },
    isActualSales() {
      const widget = this.widgets.find(val => val.requestKey === widgetKeys.REPORT_TYPE);

      return widget?.val === actualSalesKey;
    },
    isImportChangesEnabled() {
      const widget = this.widgets.find(({ requestKey }) => widgetKeys.IMPORT_CHANGES === requestKey);

      return widget?.enabled;
    }
  },
  watch: {
    configurationWidgets(val) {
      if (!val.length) {
        hideAllPoppers();
      }
    }
  },
  methods: {
    ...mapActions({
      exportTableToXlsx: 'forecastReport/exportTableToXlsx',
      importChanges: 'forecastReport/importChanges'
    }),
    toggleShowHide() {
      this.isShowHideVisible = !this.isShowHideVisible;
    },
    toggleConfiguration() {
      this.isConfigurationVisible = !this.isConfigurationVisible;
    },
    handleDropdownToggle() {
      this.isShowHideVisible = false;
      this.isConfigurationVisible = false;
    },
    handleToggleColumnVisibility(...args) {
      this.$store.dispatch(`${this.reportNamespace}/updateColumnVisibility`, ...args);
    },
    async handleExportTableToXlsx(value) {
      this.saveFile(this.exportTableToXlsx.bind(this, value));
    },
    async handleImportChanges(file) {
      try {
        const response = await this.importChanges(file);
        const message = response?.data?.payload?.message;

        if (!message) {
          return;
        }

        this.$notify({
          type: 'success',
          text: message
        });
      } catch (e) {
        this.showModal(modalsId.SL_ERROR_MODAL, {
          title: this.$t('Main.Ui.acImportInventoryFromXlsx'),
          text: e.message
        });
      } finally {
        ampli.forecastReportImported();
      }
    }
  }
};
</script>
